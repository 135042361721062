import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/zap-onboard-api/zap-onboard-api/services-unmanaged/zap-onboard-home-page/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Screen50 } from '../../../components/images/support/users/Screen50.generated';
import { Screen51 } from '../../../components/images/support/users/Screen51.generated';
import { Screen52 } from '../../../components/images/support/users/Screen52.generated';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "invite-a-supervisor-user-"
    }}>{`Invite a Supervisor User 👩‍💻`}</h1>
    <p>{`This section shows how to invite a supervisor user to Canyou. `}</p>
    <p><strong parentName="p">{`Supervisor`}</strong>{` users have limited privileges, as follows:`}</p>
    <ul>
      <li parentName="ul">{`Cannot access the `}<strong parentName="li">{`Settings`}</strong>{` menu`}</li>
      <li parentName="ul">{`Cannot invite `}<strong parentName="li">{`admin`}</strong>{` users`}</li>
      <li parentName="ul">{`Cannot access the `}<strong parentName="li">{`User Documents`}</strong>{` section within a users profile`}</li>
      <li parentName="ul">{`Cannot `}<strong parentName="li">{`Register a business`}</strong></li>
      <li parentName="ul">{`Cannot view workflows completed by other `}<strong parentName="li">{`members`}</strong></li>
    </ul>
    <hr></hr>
    <ol>
      <li parentName="ol">{`Navigate to the Users menu and click `}<strong parentName="li">{`Add user`}</strong></li>
    </ol>

    <Screen50 mdxType="Screen50" />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Select the `}<strong parentName="li">{`Supervisor`}</strong>{` user type.`}</li>
    </ol>

    <Screen51 mdxType="Screen51" />
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Complete the rest of the supervisors information and click `}<strong parentName="li">{`Add`}</strong>{`.`}</li>
    </ol>
    <p>{`This will send an email to the user inviting them to become an supervisor of the respective organisation.`}</p>
    <p>{`When the user accepts the invite they will have limited access to control the organisations Canyou account.`}</p>

    <Screen52 mdxType="Screen52" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      